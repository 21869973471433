import React from 'react';
import Logo from '../../svg/Logo';
import './Footer.css';
const Footer = () => {
  return (
    <footer className="content">
      <div className="footer">
        <div className="footer__info">
          <h6>POWERED BY</h6>
          <Logo size={100} />
          <p>connect@atlas.space</p>
        </div>
        <div className="footer__social">
          <h6>SOCIAL MEDIA / SOSYAL MEDYA</h6>

          <a
            href="#"
            alt=""
          >
            Twitter
          </a>
          <a
            href="#"
            alt=""
          >
            Instagram
          </a>
          <a
            href="#"
            alt=""
          >
            Discord
          </a>
          <a
            href="#"
            alt=""
          >
            Telegram
          </a>
        </div>
        <div className="footer__about">
          <h6>ABOUT / HAKKIMIZDA</h6>
          <p>Atlas Space is a metaverse platform and a Web3 creative team.</p>
          <p>Atlas Space bir metaverse platformu ve Web3 yaratıcı ekibidir.</p>
          <h6>@2032</h6>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
