import React from 'react';

const DonationCardClose = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4498 0.564359C19.3111 0.425304 19.1462 0.314982 18.9648 0.23971C18.7833 0.164438 18.5888 0.125693 18.3923 0.125693C18.1959 0.125693 18.0014 0.164438 17.8199 0.23971C17.6384 0.314982 17.4736 0.425304 17.3348 0.564359L9.99985 7.88436L2.66485 0.549359C2.52597 0.410486 2.36111 0.300326 2.17966 0.225168C1.99822 0.150011 1.80374 0.111328 1.60735 0.111328C1.41095 0.111328 1.21648 0.150011 1.03503 0.225168C0.853586 0.300326 0.68872 0.410486 0.549847 0.549359C0.410974 0.688231 0.300814 0.853098 0.225657 1.03454C0.150499 1.21599 0.111816 1.41046 0.111816 1.60686C0.111816 1.80326 0.150499 1.99773 0.225657 2.17917C0.300814 2.36062 0.410974 2.52549 0.549847 2.66436L7.88485 9.99936L0.549847 17.3344C0.410974 17.4732 0.300814 17.6381 0.225657 17.8195C0.150499 18.001 0.111816 18.1955 0.111816 18.3919C0.111816 18.5883 0.150499 18.7827 0.225657 18.9642C0.300814 19.1456 0.410974 19.3105 0.549847 19.4494C0.68872 19.5882 0.853586 19.6984 1.03503 19.7735C1.21648 19.8487 1.41095 19.8874 1.60735 19.8874C1.80374 19.8874 1.99822 19.8487 2.17966 19.7735C2.36111 19.6984 2.52597 19.5882 2.66485 19.4494L9.99985 12.1144L17.3348 19.4494C17.4737 19.5882 17.6386 19.6984 17.82 19.7735C18.0015 19.8487 18.1959 19.8874 18.3923 19.8874C18.5887 19.8874 18.7832 19.8487 18.9647 19.7735C19.1461 19.6984 19.311 19.5882 19.4498 19.4494C19.5887 19.3105 19.6989 19.1456 19.774 18.9642C19.8492 18.7827 19.8879 18.5883 19.8879 18.3919C19.8879 18.1955 19.8492 18.001 19.774 17.8195C19.6989 17.6381 19.5887 17.4732 19.4498 17.3344L12.1148 9.99936L19.4498 2.66436C20.0198 2.09436 20.0198 1.13436 19.4498 0.564359Z"
        fill="white"
      />
    </svg>
  );
};

export default DonationCardClose;
