import Logo from './svg/Logo';
import './App.css';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import oneMillionNFT from './contract/oneMillionNFT.json';
import MaticLogo from './img/matic.png';
import MaticLogoBlack from './img/matic-black.png';
import Cookies from 'universal-cookie';

// Local Imports
import HowToDonate from './components/howToDonate/HowToDonate';
import OurGoal from './components/ourGoal/OurGoal';
import TabContainer from './components/tabContainer/TabContainer';
import DonationSubmitModal from './components/donationSubmitModal/DonationSubmitModal';
import UseWindowDimensions from './hooks/useWindowDimensions';
import { Toaster, toast } from 'react-hot-toast';
import Footer from './components/footer/Footer';
function App() {
  const [selectAmount, setSelectAmount] = useState();
  const [manuelAmount, setManuelAmount] = useState();
  const [buttonValueName, setButtonValueName] = useState();
  const [publicAddress, setPublicAddress] = useState();
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [raisedDonation, setRaisedDonation] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const buttonsName = ['1', '40', '160', '400', '800'];
  const cookies = new Cookies();
  let addres = cookies.get('publicAddress');

  useEffect(() => {
    const getPublicAccount = async () => {
      const wssProvider = new ethers.providers.Web3Provider(window.ethereum);
      // const wssSigner = wssProvider.getSigner();
      const balance = await wssProvider.getBalance(
        '0xd3DE03D67795E3ec3367BBF2C09A021A4a9c1eF1'
      );
      const balanceParseInt = ethers.utils.formatEther(
        parseInt(balance).toString()
      );
      setRaisedDonation(balanceParseInt.split('.'));
    };

    const getSupply = async () => {
      const wssProvider = new ethers.providers.Web3Provider(window.ethereum);
      const wssSigner = wssProvider.getSigner();
      const contract = new ethers.Contract(
        '0xd3DE03D67795E3ec3367BBF2C09A021A4a9c1eF1',
        oneMillionNFT,
        wssSigner
      );
      const supply = await contract.totalSupply();
      setTotalSupply(parseInt(supply));
    };

    getSupply();
    getPublicAccount();
  }, [publicAddress]);

  const donation = raisedDonation?.[0] + '.' + raisedDonation?.[1].split('')[0];

  const changeNetwork = async () => {
    const changeNetwork = await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x89',
          rpcUrls: ['https://polygon-rpc.com/'],
          chainName: 'Matic Mainnet',
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          blockExplorerUrls: ['https://polygonscan.com/'],
        },
      ],
    });
    console.log('changing network', changeNetwork);
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      const connect = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      setPublicAddress(connect[0]);
      cookies.set('publicAddress', connect[0]);
    } else {
      console.log('not found ethers');
    }
  };

  const buyItem = async () => {
    // console.log("test", manuelAmount);
    if (!manuelAmount) {
      let ethersToWei = ethers.utils.parseUnits(selectAmount, 'ether');
      const rpc = 'https://rpc.ankr.com/polygon_mumbai';
      const provider = new ethers.providers.JsonRpcProvider(rpc);
      const wssProvider = new ethers.providers.Web3Provider(window.ethereum);
      const wssSigner = wssProvider.getSigner();
      const myPublicAddress = await wssSigner.getAddress();
      const myBalance = await wssProvider.getBalance(
        myPublicAddress.toString()
      );
      if (ethersToWei >= parseInt(myBalance)) {
        toast.error('Insufficient funds / Bakiyeniz yetersiz');
      }
      const contract = new ethers.Contract(
        '0xd3DE03D67795E3ec3367BBF2C09A021A4a9c1eF1',
        oneMillionNFT,
        wssSigner
      );
      await contract.safeMint({
        value: ethersToWei.toString(),
      });
      setShowDonationModal(true);
    } else {
      setSelectAmount('');
      const checkNumber = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.',
      ];

      const test = checkNumber.includes(manuelAmount);
      console.log('checknumber', test);

      let ethersToWei = ethers.utils.parseUnits(manuelAmount, 'ether');

      const rpc = 'https://rpc.ankr.com/polygon_mumbai';
      const provider = new ethers.providers.JsonRpcProvider(rpc);
      const wssProvider = new ethers.providers.Web3Provider(window.ethereum);
      const wssSigner = wssProvider.getSigner();
      const myPublicAddress = await wssSigner.getAddress();
      const myBalance = await wssProvider.getBalance(
        myPublicAddress.toString()
      );

      if (ethersToWei >= parseInt(myBalance)) {
        toast.error('Insufficient funds / Bakiyeniz yetersiz');
      }
      const contract = new ethers.Contract(
        '0xd3DE03D67795E3ec3367BBF2C09A021A4a9c1eF1',
        oneMillionNFT,
        wssSigner
      );
      const safeMint = await contract.safeMint({
        value: ethersToWei.toString(),
      });
      safeMint.wait(15);
      setShowDonationModal(true);
    }
  };

  const handleClick = (e) => {
    setButtonValueName(e.target.value);
    setSelectAmount(e.target.value);
  };

  const mauelAmountChange = (e) => {
    setManuelAmount(e.target.value.toString());
  };

  const disconnect = async (callback) => {
    const accounts = await window.ethereum.request({
      method: 'eth_accounts',
    });
    callback(accounts);
  };

  if (window.ethereum) {
    window.ethereum.on('accountsChanged', async () => {
      disconnect((accounts) => {
        console.log('account', accounts);

        if (accounts[0] === undefined) {
          localStorage.clear();
          cookies.remove('publicAddress');
          window.location.reload();
        }
      });
    });
  }

  const { height, width } = UseWindowDimensions();
  console.log(height, width);

  const dappUrl = 'metamask-auth.ilamanov.repl.co'; // TODO enter your dapp URL. For example: https://uniswap.exchange. (don't enter the "https://")
  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      {showDonationModal && (
        <DonationSubmitModal setShowDonationModal={setShowDonationModal} />
      )}

      <header className="navbar">
        <Logo />
        <div className="navbar__button">
          {!addres && width >= 570 ? (
            <button onClick={connectWallet}>
              Connect Wallet / Cüzdan Bağla
            </button>
          ) : (
            <button onClick={changeNetwork}>
              Change Network / Ağı Değiştir{' '}
            </button>
          )}
        </div>
      </header>
      {/* Main Component */}
      <div className="content">
        <div className="left">
          <div className="left__content">
            <h6>An NFT Fundraising Initiative for Turkey Earthquake Relief</h6>
            <h1>Blocks for Hope</h1>
            <p>call to rebuild, unite and make a difference</p>
            {width < 570 && (
              <a
                className="left__mobile"
                href={metamaskAppDeepLink}
              >
                Connect Wallet / Cüzdan Bağla
              </a>
            )}
          </div>
        </div>

        <div className="right">
          <div className="right__raised">
            <h6>
              Raised: <img src={MaticLogo} /> {donation}
            </h6>
            <h6>NFTs Minted: {totalSupply}</h6>
          </div>
          <div className="right__result">
            <h6>Toplandı:</h6>
            <h6>Mintlenen NFTler</h6>
          </div>
          {/* <div className="right__progress"></div> */}
          {/* <div className="fund__collect">
            <h6>Fund Collect: %80</h6>
            <h6>Yüzde:</h6>
          </div> */}
          <div className="right__raised__hr"></div>
          <div className="right__select__amount">
            <h4>Select Amount</h4>
            <h6>Miktar Seçiniz</h6>
            <div className="select__amount__button">
              {buttonsName.map((btn, id) => (
                <button
                  className={buttonValueName === btn ? 'active' : ''}
                  onClick={(e) => handleClick(e)}
                  value={btn}
                  key={btn}
                >
                  {buttonValueName === btn ? (
                    <img
                      src={MaticLogo}
                      alt=""
                    />
                  ) : (
                    <img
                      src={MaticLogoBlack}
                      alt=""
                    />
                  )}

                  {btn}
                </button>
              ))}
            </div>
            <div className="select__enter__input">
              <input
                type="text"
                onChange={mauelAmountChange}
                placeholder="Enter Manually (min 8 MATIC)"
              />
            </div>
            <div className="select__info">
              <h6>Elle Miktar Giriniz (minimum 8 MATIC)</h6>
            </div>
            <div className="select__enter__button orange">
              <button onClick={buyItem}>Donate Now / Bağış yap</button>
            </div>
            <div className="select__link">
              <a
                href="https://www.coinbase.com/converter/matic/usd#"
                alt=""
                target={'_blank'}
              >
                {'MATIC <> USD CONVERTER / HESAPLA '}
              </a>
            </div>
            <div className="donation__tracking">
              <h1>Track Total Funds Collected</h1>
              <h6>Toplanan bağış miktarını takip edin</h6>
              <a href="#">linklinkkladsfijdfsakiadlfjklfdajikldf</a>
            </div>
          </div>
        </div>
      </div>

      {/* Descriptions Component */}
      <div className="content">
        <TabContainer>
          <OurGoal />
          <HowToDonate />
        </TabContainer>
      </div>
      <Footer />
    </>
  );
}

export default App;
