import React, { useState } from "react";
import "./HowToDonate.css";
const HowToDonate = () => {
  const [language, setLanguage] = useState("en");

  return (
    <div className="right__tab">
      <div className="right__tab__button">
        <h6
          className={language === "en" ? "active" : ""}
          onClick={() => setLanguage("en")}
        >
          HOW (EN)
        </h6>
        <h6
          className={language === "tr" ? "active" : ""}
          onClick={() => setLanguage("tr")}
        >
          NASIL (TR)
        </h6>
      </div>
      <div className="right__tab__line">
        <div
          className={
            language === "tr" ? "tab__line__color  active" : "tab__line__color "
          }
        ></div>
      </div>
      {/* Eng */}
      {language === "en" && (
        <div className="right__tab__content">
          <div>
            <h1>
              Transparent Process for <br /> Housing Support
            </h1>
            <p>
              Our donation campaign will be on the blockchain and every incoming
              donation will be written on smart contracts and distributed in a
              transparent and traceable process to stakeholders.
              https://etherscan.io/address/linklinklink
            </p>
          </div>

          <button className="right__donate__button">how to donate</button>
        </div>
      )}

      {/* Tr */}
      {language === "tr" && (
        <div className="right__tab__content">
          <div>
            <h1>Depremzedelere Barınma Desteği için Şeffaf Süreç</h1>
            <p>
              Bağış kampanyamız blokzincirde yer alacak ve her gelen bağış
              akıllı kontratlara yazılıp paydaşlara şeffaf ve takip edilebilir
              bir süreçte dağıtılacaktır.
              https://etherscan.io/address/linklinklink
            </p>
          </div>

          <button className="right__donate__button">NASIL BAĞIŞ YAPARIM</button>
        </div>
      )}
    </div>
  );
};

export default HowToDonate;
