import React, { useState } from "react";
import "./OurGoal.css";
const OurGoal = () => {
  const [language, setLanguage] = useState("en");

  return (
    <div className="left__tab">
      <div className="left__tab__button">
        <h6
          className={language === "en" ? "active" : ""}
          onClick={() => setLanguage("en")}
        >
          OUR GOAL (EN)
        </h6>
        <h6
          className={language === "tr" ? "active" : ""}
          onClick={() => setLanguage("tr")}
        >
          AMACIMIZ (TR)
        </h6>
      </div>
      <div className="left__tab__line">
        <div
          className={
            language === "tr" ? "tab__line__color  active" : "tab__line__color "
          }
        ></div>
      </div>
      {/* Eng */}
      {language === "en" && (
        <div className="left__tab__content">
          <div>
            <h1>
              Blocks for Hope <br /> An NFT Fundraising Initiative
            </h1>
            <p>
              "Blocks for Hope" is a fundraising NFT campaign for earthquake
              relief in Southeast Turkey. A devastating disaster has left many
              people in need of support over 10 cities in Turkey. Our goal is to
              be a part of the reconstruction, block by block, to restore and
              rebuild the affected areas for the long term.
            </p>
          </div>

          <div className="left__tab__con">
            <h6>Logistics Rebuilding</h6>
            <h6>Communication Infrastructure</h6>
            <h6>Health Shelter</h6>
            <h6>Food Power</h6>
          </div>
        </div>
      )}

      {/* Tr */}
      {language === "tr" && (
        <div className="left__tab__content">
          <div>
            <h1>
              Umut için Bloklar <br /> Bir NFT Bağış Kampanyası
            </h1>
            <p>
              "Umut için Bloklar", 6 Şubat deprem mağdurlarına yardım etmek için
              oluşturulan bir NFT bağış kampanyasıdır. Depremin 10 şehirdeki
              yıkıcı etkileri hepimiz derinden sarsmıştır. Amacımız, etkilenen
              bölgelerin uzun vadede yeniden yapılanması için ortaya çıkan
              ihtiyaçlara katkıda bulunmaktır.
            </p>
          </div>

          <div className="left__tab__con">
            <h6>Lojistik İnşaat</h6>
            <h6>İletişim Altyapı</h6>
            <h6>Sağlık Barınma</h6>
            <h6>Gıda Güç Kaynağı</h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default OurGoal;
