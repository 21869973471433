import React from 'react';

const DonationCardOk = () => {
  return (
    <svg
      width="83"
      height="82"
      viewBox="0 0 83 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.4998 0.166016C18.9598 0.166016 0.666504 18.4593 0.666504 40.9993C0.666504 63.5393 18.9598 81.8327 41.4998 81.8327C64.0398 81.8327 82.3332 63.5393 82.3332 40.9993C82.3332 18.4593 64.0398 0.166016 41.4998 0.166016ZM33.3332 61.416L12.9165 40.9993L18.674 35.2418L33.3332 49.8602L64.3257 18.8677L70.0832 24.666L33.3332 61.416Z"
        fill="white"
      />
    </svg>
  );
};

export default DonationCardOk;
