import React from 'react';
import DonationCardClose from '../../svg/DonationCardClose';
import DonationCardOk from '../../svg/DonationCardOk';
import './DonationSubmitModal.css';
const DonationSubmitModal = ({ setShowDonationModal }) => {
  return (
    <div className="donation">
      <div className="donation__wrapper">
        <div className="donation__close">
          <button onClick={() => setShowDonationModal(false)}>
            <DonationCardClose />
          </button>
        </div>
        <div className="donation__content">
          <DonationCardOk />
          <div className="donation__title">
            <h1>Thank you for your donation!</h1>
            <h6>Bağışlarınız için teşekkürler!</h6>
          </div>
          <button>Download / İndİr</button>
        </div>
      </div>
    </div>
  );
};

export default DonationSubmitModal;
